// polyfill for NodeList#forEach - see
//   https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
//   https://caniuse.com/#feat=mdn-api_nodelist_foreach
//
// Sentry shows this is about 5,000 errors a day:
//   https://sentry.io/organizations/hover/issues/1623405571/?project=164872&query=is%3Aunresolved
if (window.NodeList && !NodeList.prototype.forEach) {
   NodeList.prototype.forEach = Array.prototype.forEach;
}

// menu
(function() {
  const MENU_TRIGGER_SELECTOR = "[data-trigger='menu']"
  const MENU_VISIBLE_CLASS = "mobile-menu-is-visible"

  const menuTriggers = document.querySelectorAll(MENU_TRIGGER_SELECTOR)
  if (!menuTriggers.length) return

  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    const action = document.body.classList.contains(MENU_VISIBLE_CLASS) ? "remove" : "add"
    document.body.classList[action](MENU_VISIBLE_CLASS)
  }

  menuTriggers.forEach(trigger => trigger.addEventListener("click", onClick))
})();

export function initSignin() {
  const MENU_SELECTOR = ".signin__menu"
  const MENU_TRIGGER_SELECTOR = "[data-trigger='signin']"
  const VISIBLE_CLASS = "is-visible"

  const menu = document.querySelector(MENU_SELECTOR)
  const menuTrigger = document.querySelector(MENU_TRIGGER_SELECTOR)

  if (!menu || !menuTrigger) return

  // hide menu if there's a click outside of it
  document.addEventListener("click", () => menu.classList.remove(VISIBLE_CLASS))
  menu.addEventListener("click", e => e.stopPropagation())

  // toggle menu when the trigger is clicked
  menuTrigger.addEventListener("click", e => {
    e.preventDefault()
    e.stopPropagation()
    const action = menu.classList.contains(VISIBLE_CLASS) ? "remove" : "add"
    menu.classList[action](VISIBLE_CLASS)
  })
}

// signin
(function() {
  initSignin();
})();


(function() {

var pmatch = location.pathname;

// if(pmatch == "signin") { pmatch = "domains"}
var elem = document.querySelector('nav a[href="' + pmatch + '"]');
if(elem && pmatch !== "/cart") {
  elem.classList.add("active");
}
})();
